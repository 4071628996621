import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  RouteMeta,
} from "vue-router";
import { defineComponent } from "vue";
import Layout from "../views/Layout.vue";

export interface AppRouteRecordRaw extends Omit<RouteRecordRaw, "meta"> {
  name?: string;
  meta?: RouteMeta;
  component?: Component | string;
  components?: Component;
  children?: AppRouteRecordRaw[];
  fullPath?: string;
  props?: any;
}

export type Component<T = any> =
  | ReturnType<typeof defineComponent>
  | (() => Promise<typeof import("*.vue")>)
  | (() => Promise<T>);

const routes: Array<AppRouteRecordRaw> = [
  {
    path: "/",
    redirect: "/layout",
  },
  {
    path: "/layout",
    redirect: "/layout/home",
    name: "layout",
    component: Layout,
    children: [
      {
        path: "home",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "home-view" */ "@/views/home-view/HomeView.vue"
          ),
      },
      {
        path: "map2d",
        name: "map2d",
        component: () => import("@/views/product/Map2d.vue"),
      },
      {
        path: "map3d",
        name: "map3d",
        component: () => import("@/views/product/Map3d.vue"),
      },
      {
        path: "model",
        name: "model",
        component: () => import("@/views/product/Model.vue"),
      },
      {
        path: "example",
        name: "example",
        component: () =>
          import(
            /* webpackChunkName: "example" */ "@/views/example/Example.vue"
          ),
      },
      {
        path: "contact",
        name: "contact",
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "@/views/contact/Contact.vue"
          ),
      },
      {
        path: "functionalTwo",
        name: "functionalTwo",
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "@/views/functional/FunctionalTwo.vue"
          ),
      },
      {
        path: "functionalThree",
        name: "functionalThree",
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "@/views/functional/FunctionalThree.vue"
          ),
      },
      {
        path: "dataDownload",
        name: "dataDownload",
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "@/views/downLoad/Index.vue"
          ),
      },
      {
        path: "caseCode",
        name: "caseCode",
        component: () =>
          import(
            /* webpackChunkName: "contact" */ "@/views/downLoad/ExampleCode.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
