<template>
  <div class="fixed bottom-[100px] right-[30px] z-[100] text-fff" style="display:none;">
    <div
      class="bg-[#FF7900] w-[44px] flex flex-col py-[4px] items-center rounded cursor-pointer"
    >
      <span class="iconfont icon-weixin"></span>
      <span>微信</span>
      <span>咨询</span>
    </div>
    <div
      class="bg-primary w-[44px] flex flex-col items-center py-[4px] my-[10px] cursor-pointer rounded"
    >
      <span class="icon-phone iconfont"></span>
      <span>电话</span>
      <span>联系</span>
    </div>

    <a-back-top :visibilityHeight="0" class="right-[30px] w-[44px]">
      <div class="text-center rounded bg-primary">
        <span class="icon-zhiding iconfont text-fff"></span>
      </div>
    </a-back-top>
  </div>
</template>
