import axios from 'axios'
import {tansParams} from './utils'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
    // config.headers['Authorization'] = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImYwMGJiNGI1LTFlMzMtNGIyYS05ZmI0LWVkNTM3ZGNmMjAxOCJ9.8f80mL2ShH1cJzHQ7QO0J5jETEKUv0JUAT01ogRhgq6YvPjlWSTMM37y1yQN0RWY3S11TfA-NPtES3v_pNDueA'
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  return Promise.reject(error);
});

export default service












