import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.variable.min.css";
import "./styles/index.less";



window._AMapSecurityConfig = {
  securityJsCode: "2ebf24d638cffa30ebc43d882265fc9c",
};
const app = createApp(App);

app.use(router).mount("#app");
app.use(Antd);
