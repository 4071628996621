
import request from '@/utils/request'

/**
 * 官网访问统计
 * @returns 
 */
export function websiteCount() {
  return request({
    url: '/system/website/website',
    method: 'get'
  })
}













